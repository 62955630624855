.siteHeader {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid navy;
    border-radius: 20px;
    margin: 10px;
    text-align: center;
    align-items: center;
}

.headerLogo {
    width: 10%;
    height: auto;
    padding: 30px 0;
}
.headerCol {
    display: flex;
    flex-direction: column;
}
.headerGroup {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.headerGroup .circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
}

@media only screen and (max-width: 1200px) {
    h1 {
        font-size: 2rem;
    }
    .headerGroup {
        gap: 5px
    }
}
