.home {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
}
h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 3rem;
    color: #4d4d4d;
}
.sites {
    width: 100%;
    margin: 2% auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.sites span {
    width: 25vw;
    height: 10vh;
    font-size: 2rem;
    border: 1px solid #4d4d4d;
    padding: 2%;
    text-align: center;
    border-radius: 20px;
    box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.5);
    background-color: #f2f2f2;
    margin-top: 3%;
}
.sites span:hover {
    box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.5);
}

footer {
    margin-top: 1%;
    text-align: center;
    font-size: 1.5rem;
    /* color: #65c4e0; */
}
footer a {
    color: #000;
}
