main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
}
.facereq {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1%;
}
.image {
    border: 1px solid #2c53a0;
    width: 30vw;
    height: 30vh;
    border-radius: 0.5rem;
    overflow: hidden;
}
.photo:nth-child(1) {
    margin-right: 5%;
}

.image > img {
    width: 100%; /* or any custom size */
    height: 100%;
    object-fit: contain;
}

.photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -5%;
}
.photo > strong {
    font-size: 1.5rem;
    object-fit: contain;
}

h4 {
    font-size: 5rem;
    text-transform: uppercase;
    color: #b1b1b1;
    transform: rotate(-35deg);
}
table {
    border: 1px solid #000;
    margin-top: 10px;
    width: 50vw;
    border-collapse: collapse;
}
thead > tr {
    border-bottom: 1px solid #000;
}
tbody {
    font-weight: 400;
}
.row > label {
    margin-left: 3%;
}
input {
    border: 1px solid #2c53a0;
    border-radius: 0.5rem;
}
select {
    border: 1px solid #2c53a0;
    border-radius: 0.5rem;
}
.addPerson > input[type="file"] {
    border: 1px solid #2c53a0;
    margin-bottom: 2%;
}

.addPerson > button {
    background-image: linear-gradient(#42a1ec, #0070c9);
    border: 1px solid #0077cc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    direction: ltr;
    display: block;
    font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue",
        Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.022em;
    line-height: 1.47059;
    min-width: 30px;
    overflow: visible;
    padding: 4px 15px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
}
.addPerson > button:disabled {
    cursor: default;
    opacity: 0.3;
}
.addPerson > button:hover {
    background-image: linear-gradient(#51a9ee, #147bcd);
    border-color: #1482d0;
    text-decoration: none;
}
.addPerson > button:active {
    background-image: linear-gradient(#3d94d9, #0067b9);
    border-color: #006dbc;
    outline: none;
}
.addPerson > button:focus {
    box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
    outline: none;
}
.addPerson > div {
    width: 50%;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}
.addPerson > div > input,
select {
    width: 70%;
    margin-left: 5px;
}
main > button {
    margin-top: 2%;
    background-image: linear-gradient(#42a1ec, #0070c9);
    border: 1px solid #0077cc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    direction: ltr;
    display: block;
    font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue",
        Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.022em;
    line-height: 1.47059;
    min-width: 30px;
    overflow: visible;
    padding: 4px 15px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
}
main > button:disabled {
    cursor: default;
    opacity: 0.3;
}
main > button:hover {
    background-image: linear-gradient(#51a9ee, #147bcd);
    border-color: #1482d0;
    text-decoration: none;
}
main > button:active {
    background-image: linear-gradient(#3d94d9, #0067b9);
    border-color: #006dbc;
    outline: none;
}
main > button:focus {
    box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
    outline: none;
}
