.pumps {
    display: flex;
    flex-direction: column;
}

.pumpRow {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 0.5%;
    align-items: center;
    /* border-bottom: 1px solid black; */
}

.pumpRow:nth-child(1) {
    border-bottom: 2px solid #4d4d4d;
}

.car {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pump {
    width: 5vw;
    height: 20vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #000;
}

.pumpNumber {
    color: #fff;
    font-size: 4rem;
}

.car p {
    text-transform: uppercase;
    font-weight: bold;
}

.car span {
    border: 2px solid #000;
    color: #000;
    background-color: #f2cb05;
    border-radius: 5px;
    padding: 1%;
    font-weight: bold;
    text-transform: uppercase;
}

h2 {
    text-align: center;
    text-transform: uppercase;
    color: #4d4d4d;
    font-size: 2.5rem;
}

.history {
    width: 60%;
    position: absolute;
    top: 4vh;
    left: 20%;
    z-index: 10;
    padding: 2%;
    background-color: #f2f2f2;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.history table {
    width: 80%;
    border-collapse: collapse;
    margin: auto;
}

thead th {
    text-align: center;
    background-color: #d3d4d6;
    color: #4d4d4d;
}

table td,
th {
    border: 1px solid #4d4d4d;
    padding: 1%;
    text-align: center;
}

tr:nth-child(even) {
    background-color: #d3d4d6;
}

.alert {
    width: 100vw;
    position: fixed;
    top: 0;
}

#btn {
    display: none;
}

li {
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin: 0;
}

.dvlaDetails {
    font-weight: bold;
    color: red;
    font-family: 2.5rem;
}

.testing {
    position: absolute;
    width: 30vw;
    height: 27vh;
    left: 55%;
    top: 30%;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 4rem;
    color: #f2cb05;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    text-align: center;
    border: 5px solid;
    border-image: linear-gradient(
            45deg,
            black,
            #ffd500,
            black,
            #ffd500,
            black,
            #ffd500,
            black,
            #ffd500,
            black
        )
        5;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: test 4s infinite;
}

@keyframes test {
    0% {
        border-image: linear-gradient(
                45deg,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black
            )
            5;
    }

    25% {
        border-image: linear-gradient(
                45deg,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500
            )
            5;
    }

    50% {
        border-image: linear-gradient(
                45deg,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black
            )
            5;
    }

    75% {
        border-image: linear-gradient(
                45deg,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500
            )
            5;
    }

    100% {
        border-image: linear-gradient(
                45deg,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black,
                #ffd500,
                black
            )
            5;
    }
}

.problemModal {
    border-radius: 10px;
    padding: 20px;
    background-color: lightgrey;
    border: 2px solid navy;
    text-align: center;
    position: absolute;
    top: 20vh;
    left: 33vw;
    z-index: 100;
}

.problemModal button {
    padding: 15px;
    border: 1px solid black;
    font-size: 1rem;
    margin-top: 15px;
    margin: 10px;
}

.problemModal img {
    border: 1px solid black;
}

@keyframes changeBack {
    0% {
        background-color: #fff;
    }

    100% {
        background-color: #d90d1e;
    }
}

.noEntry {
    position: absolute;
    z-index: 20;
    padding-top: 35px;
}

.noEntry p {
    position: inherit;
    z-index: 25;
    color: #fff;
    font-size: 3rem;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

.noEntry img {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 55%;
    animation: entryFlash 0.5s infinite;
}

@keyframes entryFlash {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.25;
    }

    50% {
        opacity: 0.5;
    }

    75% {
        opacity: 0.75;
    }

    100% {
        opacity: 1;
    }
}

#faceReq_modal {
    position: absolute;
    top: 5vh;
    left: 5vw;
    height: 90vh;
    width: 90vw;
    background-color: #fff;
    border-radius: 1rem;
    z-index: 10;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    animation: changeBack 1.5s infinite;
    display: none;
}

#faceReq_modal > .icon {
    position: inherit;
    top: 2%;
    right: 2%;
}

#faceReq_modal > .row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1%;
}

#faceReq_modal > .image {
    border: 1px solid #2c53a0;
    width: 30vw;
    height: 45vh;
    border-radius: 0.5rem;
}

#faceReq_modal > .image:nth-child(1) {
    margin-right: 5%;
}

#faceReq_modal > .image > img {
    align-self: center;
    margin-top: 10%;
}

#faceReq_modal > h4 {
    font-size: 5rem;
    text-transform: uppercase;
    color: #b1b1b1;
    transform: rotate(-35deg);
}

#faceReq_modal > table {
    border: 1px solid #000;
    margin: 3% auto;
    width: 50vw;
    border-collapse: collapse;
}

#faceReq_modal > thead > tr {
    border-bottom: 1px solid #000;
}

#faceReq_modal > tbody {
    font-weight: 400;
}

#faceReq_modal > table > tbody > tr {
    background-color: #fff;
}

@media (max-width: 1200px) {
    .pumpRow {
        width: 95%;
    }

    .pumpNumber {
        font-size: 2rem;
    }

    .car {
        width: 100%;
    }
    li {
        font-size: 1.1rem;
        width: 90%;
        display: flex;
        justify-content: space-between;
    }
}
